/*
This module is a WIP intended to better organize the z-index values in our
codebase.

Right now, our z-index values are all hardcoded and with arbitrary values, like
9999999 or similar. This ends up causing display issues that are hard to debug
and fix.

We should define a few z-indexes values only for the main layers of the app
(menus, modals, header, notifications, etc.), and define the stack order of
other components through a well organized HTML DOM tree structure.

In the future, all the z-index values in our app should be defined only in this
module. This will make the stack order of the layers more clear. These values
can be directly used in .scss (since it's included in globals.scss) and also in
TS (by exposing the variable from zIndexes.module.scss file).
*/

/* stylelint-disable scss/dollar-variable-pattern */

$theaterViewZIndex: 0;
$panContainerZIndex: 0;
$positionalContainerZIndex: 3;
$broadcastAreaZIndex: 5;
$broadcastMenuZIndex: 10;
$broadcastDialogZIndex: 1000;
$conversationMenuZIndex: 2;
$conversationDialogZIndex: 50;
$tooltipZIndex: 1000001;
$fileUploaderZIndex: 9999;
$modalZIndex: 1000000;
$notificationsZIndex: 1000010;
$remoHoverZIndex: 0;
$remoHoverBackgroundZIndex: -1;
$presentationLayoutZIndex: 2;
$presentationMessageZIndex: 0;
$pageLoadingZIndex: 9999999;
$mapAvatarContainerZIndex: 6;
$popover: 1700;
$tryCamAndMicPopoverZIndex: 3;
$dialogArrowZIndex: 10;
$onboardBackgroundZIndex: 10000;
$announcementNotificationZIndex: 9999999;
$emojiReactionsContainerZIndex: 7;
$emojiReactionZIndex: 2;
$whiteboardButtonZIndex: 9;
$fakeStreamZIndex: 3;
$helpChatZIndex: 999999;
$timerPanelZIndex: 1199;
$broadcastTotalRequestsZIndex: 100;
$eventDirectoryMenuZIndex: 1000001;
$eventDirectorySwitcherZIndex: 20;
$activeSpeakerDetectionAnimZIndex: 2;
$inviteGuestsZIndex: 1000001;
$pollsPercentageBarZIndex: 1;
$remoBadgeIndex: 1;
$joinEventModalZIndex: 999999;
$ticketsModalZIndex: $joinEventModalZIndex + 1;
$muiModalZIndex: 1000;
